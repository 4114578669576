module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1800,"withWebp":true,"linkImagesToOriginal":false,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","fr"],"defaultLanguage":"en","siteUrl":"https://reflektlab.ai/","trailingSlash":"always","i18nextOptions":{"interpolation":{"escapeValue":false},"fallbackLng":"en","supportedLngs":["en","fr"],"keySeparator":".","nsSeparator":".","useLangKeyLayout":false},"pages":[{"matchPath":"/:lang?/404","getLanguageFromPath":true,"excludeLanguages":["en"]},{"matchPath":"/:lang?/chat","getLanguageFromPath":true,"excludeLanguages":["en"]},{"matchPath":"/:lang?/contact","getLanguageFromPath":true,"excludeLanguages":["en"]},{"matchPath":"/:lang?/contract","getLanguageFromPath":true,"excludeLanguages":["en"]},{"matchPath":"/:lang?/crm","getLanguageFromPath":true,"excludeLanguages":["en"]},{"matchPath":"/:lang?/index","getLanguageFromPath":true,"excludeLanguages":["en"]},{"matchPath":"/:lang?/leads","getLanguageFromPath":true,"excludeLanguages":["en"]},{"matchPath":"/:lang?/recruitment","getLanguageFromPath":true,"excludeLanguages":["en"]},{"matchPath":"/:lang?/technology","getLanguageFromPath":true,"excludeLanguages":["en"]},{"matchPath":"/:lang?/blog/:slug","getLanguageFromPath":true,"excludeLanguages":["en"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TWMGTLWN","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Reflekt Lab","short_name":"rflkt lab","start_url":"/","background_color":"#DFEEFD","theme_color":"#DFEEFD","display":"browser","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"72a04e8880f1e6572ba53a4762d6e194"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
